<template>
  <y_layout>
    <div class="flex-def flex-zCenter flex-cCenter" style="height: 100%">
      <div class="box-card flex-def">
        <div class="form-box flex-def flex-zCenter flex-cStart">
          <div class="form-box-item flex-def flex-zCenter flex-cCenter flex-zTopBottom">
            <div style="font-weight: 600;font-size: 1.6rem;padding: 2rem 0;margin-top: 1rem">个人账号资料设置</div>
            <el-form label-position="left" label-width="6rem" :model="ruleForm" ref="ruleForm"
                     class="ruleForm">
              <el-form-item label="登陆账号：" prop="account">
                <div class="flex-def flex-zBetween">
                  <span>{{ $store.state.sys.account.info.username }}</span>
                  <span v-if="$store.state.sys.account.info.status != 2" class="y-desc"
                        style="color: red">{{ $store.state.sys.account.info.status_str }}</span>
                </div>
              </el-form-item>
              <el-form-item label="新密码：" prop="password">
                <el-input type="password" show-password v-model="ruleForm.password" placeholder="请输入您的登陆密码"></el-input>
              </el-form-item>
              <el-form-item label="新密码：" prop="re_password">
                <el-input type="password" show-password v-model="ruleForm.re_password" placeholder="请再次输入您的登陆密码"></el-input>
              </el-form-item>
            </el-form>
            <div style="width: 80%;margin-top: 1rem">
              <el-button @click="submit" type="primary" style="width: 100%">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </y_layout>
</template>

<script>
import {mapActions} from "vuex"
import y_layout from "@/components/account/y_layout";
export default {
  name: "info",
  components:{
    y_layout
  },
  data() {
    return {
      ruleForm: {
        password:"",
        re_password:"",
      },
    }
  },
  mounted() {
    this.info();
  },
  methods: {
    submit(){
      this.$api.sys.account.resetPassword(this.ruleForm).then(()=>{
        this.$message.success("操作成功，请重新登陆");
        this.logout();
      })
    },
    ...mapActions("sys/account", {
      info: 'loadInfo',
      logout: 'logout'
    })
  }
}
</script>

<style scoped>
.el-form--label-top .el-form-item__label {
  font-weight: 600;
  padding-bottom: 0 !important;
}

.ruleForm {
  width: 100%;

}

.form-box-item {
  width: 22rem;
}

.form-box {
  width: 80rem;
  height: 100%;
}
.box-card{
  min-width: 32.5rem;
  height: 40rem;
  background-color: #FFFFFF;
}
</style>